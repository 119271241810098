/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { RiArrowRightSLine, RiCheckLine, RiRoadMapLine, RiLightbulbLine, RiTimeLine, RiQuestionLine, RiDownloadLine, RiFileTextLine } from "react-icons/ri"

const DevelopmentPlanTemplatePage = () => {
  return (
    <Layout>
      <SEO 
        title="Professional Development Plan Template | Career Growth Planning Tool"
        description="Create effective professional development plans with our comprehensive template. Perfect for career advancement, skill development, and performance management. Free downloadable templates."
        keywords={[
          "professional development plan",
          "career growth template",
          "employee development tool",
          "personal development planning",
          "skill development framework",
          "career advancement template",
          "professional growth plan",
          "development action plan",
          "competency development template",
          "learning plan template"
        ]}
      />
      
      {/* Hero Section */}
      <div sx={{
        background: 'linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)',
        py: [4, 5],
        position: 'relative'
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{
            textAlign: 'center',
            maxWidth: '800px',
            mx: 'auto'
          }}>
            <h1 sx={{
              fontSize: ['2rem', '2.5rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary'
            }}>
              Professional Development Plan Template
            </h1>
            
            <p sx={{
              fontSize: '1.1rem',
              lineHeight: 1.5,
              mb: 4,
              color: 'text',
              opacity: 0.9
            }}>
              Create a structured plan for professional growth and career advancement. 
              Our template helps identify development needs, set learning objectives, 
              and track progress toward career goals.
            </p>
            
            <div sx={{
              display: 'flex',
              gap: 3,
              justifyContent: 'center',
              flexWrap: 'wrap'
            }}>
              <a href="#download" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                bg: 'primary',
                color: 'white',
                py: 2,
                px: 3,
                borderRadius: '6px',
                fontSize: '0.95rem',
                fontWeight: 600,
                textDecoration: 'none',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 6px 15px rgba(0,0,0,0.15)'
                }
              }}>
                Download Template <RiDownloadLine />
              </a>
              
              <a href="#examples" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                bg: 'white',
                color: 'primary',
                py: 2,
                px: 3,
                borderRadius: '6px',
                fontSize: '0.95rem',
                fontWeight: 600,
                textDecoration: 'none',
                border: '2px solid',
                borderColor: 'primary',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                boxShadow: '0 4px 10px rgba(0,0,0,0.05)',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                }
              }}>
                View Examples <RiFileTextLine />
              </a>
            </div>
          </div>
        </div>
      </div>
      
      {/* Main Content */}
      <div sx={{
        bg: 'white',
        py: [4, 5]
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3,
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr', '2fr 1fr'],
          gap: 5
        }}>
          {/* Left Column - Main Content */}
          <div>
            {/* Development Plan Framework */}
            <div sx={{ 
              mb: 4,
              pb: 4,
              borderBottom: '1px solid',
              borderColor: 'rgba(0,0,0,0.06)'
            }}>
              <h2 sx={{
                fontSize: '1.75rem',
                fontWeight: 700,
                color: 'text',
                mb: 3
              }}>
                The Development Plan Framework
              </h2>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 4
              }}>
                An effective professional development plan provides a structured approach to growth, 
                helping you identify goals, required skills, and action steps for career advancement.
              </p>
              
              <div sx={{
                display: 'grid',
                gridTemplateColumns: ['1fr', '1fr 1fr'],
                gap: 3,
                mb: 4
              }}>
                <div sx={{
                  bg: 'backgroundMuted',
                  p: 3,
                  borderRadius: '8px'
                }}>
                  <h3 sx={{
                    fontSize: '1.25rem',
                    fontWeight: 600,
                    color: 'text',
                    mb: 2,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2
                  }}>
                    <RiRoadMapLine sx={{ color: 'primary' }} />
                    Self-Assessment
                  </h3>
                  <p sx={{
                    fontSize: '0.95rem',
                    color: 'text',
                    lineHeight: 1.5
                  }}>
                    Evaluate current skills, strengths, and areas for improvement. Identify career aspirations and development needs.
                  </p>
                </div>
                
                <div sx={{
                  bg: 'backgroundMuted',
                  p: 3,
                  borderRadius: '8px'
                }}>
                  <h3 sx={{
                    fontSize: '1.25rem',
                    fontWeight: 600,
                    color: 'text',
                    mb: 2,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2
                  }}>
                    <RiLightbulbLine sx={{ color: 'primary' }} />
                    Development Goals
                  </h3>
                  <p sx={{
                    fontSize: '0.95rem',
                    color: 'text',
                    lineHeight: 1.5
                  }}>
                    Set clear, measurable goals for skills to develop, knowledge to acquire, and competencies to improve.
                  </p>
                </div>
                
                <div sx={{
                  bg: 'backgroundMuted',
                  p: 3,
                  borderRadius: '8px'
                }}>
                  <h3 sx={{
                    fontSize: '1.25rem',
                    fontWeight: 600,
                    color: 'text',
                    mb: 2,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2
                  }}>
                    <RiCheckLine sx={{ color: 'primary' }} />
                    Action Steps
                  </h3>
                  <p sx={{
                    fontSize: '0.95rem',
                    color: 'text',
                    lineHeight: 1.5
                  }}>
                    Define specific activities to achieve each goal: training, projects, mentoring, reading, or hands-on practice.
                  </p>
                </div>
                
                <div sx={{
                  bg: 'backgroundMuted',
                  p: 3,
                  borderRadius: '8px'
                }}>
                  <h3 sx={{
                    fontSize: '1.25rem',
                    fontWeight: 600,
                    color: 'text',
                    mb: 2,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2
                  }}>
                    <RiTimeLine sx={{ color: 'primary' }} />
                    Timelines
                  </h3>
                  <p sx={{
                    fontSize: '0.95rem',
                    color: 'text',
                    lineHeight: 1.5
                  }}>
                    Set realistic timeframes for completing each action step and achieving development goals.
                  </p>
                </div>
                
                <div sx={{
                  bg: 'backgroundMuted',
                  p: 3,
                  borderRadius: '8px',
                  gridColumn: ['auto', '1 / -1']
                }}>
                  <h3 sx={{
                    fontSize: '1.25rem',
                    fontWeight: 600,
                    color: 'text',
                    mb: 2,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2
                  }}>
                    <RiCheckLine sx={{ color: 'primary' }} />
                    Progress Tracking
                  </h3>
                  <p sx={{
                    fontSize: '0.95rem',
                    color: 'text',
                    lineHeight: 1.5
                  }}>
                    Regularly review progress, document achievements, and adjust plans as needed to stay on track.
                  </p>
                </div>
              </div>
            </div>
            
            {/* Example Development Plans */}
            <div id="examples" sx={{ 
              mb: 4,
              pb: 4,
              borderBottom: '1px solid',
              borderColor: 'rgba(0,0,0,0.06)'
            }}>
              <h2 sx={{
                fontSize: '1.75rem',
                fontWeight: 700,
                color: 'text',
                mb: 3
              }}>
                Example Development Plans
              </h2>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 4
              }}>
                Review these example development plans to inspire your own professional growth strategy:
              </p>
              
              <div sx={{
                mb: 4
              }}>
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  color: 'text',
                  mb: 2,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <RiLightbulbLine sx={{ color: 'primary' }} />
                  Leadership Development Example
                </h3>
                
                <div sx={{
                  bg: 'backgroundMuted',
                  p: 3,
                  borderRadius: '8px',
                  mb: 3
                }}>
                  <p sx={{
                    fontSize: '0.95rem',
                    fontWeight: 600,
                    color: 'text',
                    mb: 1
                  }}>
                    Goal: Develop team leadership skills to prepare for a management role within 12 months
                  </p>
                  
                  <ul sx={{
                    listStyle: 'none',
                    p: 0,
                    m: 0
                  }}>
                    <li sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      gap: 2,
                      mb: 2,
                      fontSize: '0.9rem',
                      color: 'text'
                    }}>
                      <RiCheckLine sx={{ color: 'primary', flexShrink: 0, mt: 1 }} />
                      <span>
                        <strong>Action 1:</strong> Complete leadership fundamentals course (3 months)
                      </span>
                    </li>
                    <li sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      gap: 2,
                      mb: 2,
                      fontSize: '0.9rem',
                      color: 'text'
                    }}>
                      <RiCheckLine sx={{ color: 'primary', flexShrink: 0, mt: 1 }} />
                      <span>
                        <strong>Action 2:</strong> Lead two cross-functional projects to practice delegation and team coordination (6 months)
                      </span>
                    </li>
                    <li sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      gap: 2,
                      fontSize: '0.9rem',
                      color: 'text'
                    }}>
                      <RiCheckLine sx={{ color: 'primary', flexShrink: 0, mt: 1 }} />
                      <span>
                        <strong>Action 3:</strong> Establish mentoring relationship with senior manager for monthly guidance (ongoing)
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              
              <div>
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  color: 'text',
                  mb: 2,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <RiLightbulbLine sx={{ color: 'primary' }} />
                  Technical Skill Development Example
                </h3>
                
                <div sx={{
                  bg: 'backgroundMuted',
                  p: 3,
                  borderRadius: '8px'
                }}>
                  <p sx={{
                    fontSize: '0.95rem',
                    fontWeight: 600,
                    color: 'text',
                    mb: 1
                  }}>
                    Goal: Develop advanced data analysis skills to improve business decision-making within 6 months
                  </p>
                  
                  <ul sx={{
                    listStyle: 'none',
                    p: 0,
                    m: 0
                  }}>
                    <li sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      gap: 2,
                      mb: 2,
                      fontSize: '0.9rem',
                      color: 'text'
                    }}>
                      <RiCheckLine sx={{ color: 'primary', flexShrink: 0, mt: 1 }} />
                      <span>
                        <strong>Action 1:</strong> Complete online data analytics certification course (2 months)
                      </span>
                    </li>
                    <li sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      gap: 2,
                      mb: 2,
                      fontSize: '0.9rem',
                      color: 'text'
                    }}>
                      <RiCheckLine sx={{ color: 'primary', flexShrink: 0, mt: 1 }} />
                      <span>
                        <strong>Action 2:</strong> Apply new skills by analyzing department performance data and presenting insights (3 months)
                      </span>
                    </li>
                    <li sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      gap: 2,
                      fontSize: '0.9rem',
                      color: 'text'
                    }}>
                      <RiCheckLine sx={{ color: 'primary', flexShrink: 0, mt: 1 }} />
                      <span>
                        <strong>Action 3:</strong> Collaborate with BI team on dashboarding project to enhance visualization skills (5-6 months)
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            
            {/* Template Usage Guide */}
            <div sx={{ mb: 4 }}>
              <h2 sx={{
                fontSize: '1.75rem',
                fontWeight: 700,
                color: 'text',
                mb: 3
              }}>
                How to Use the Template
              </h2>
              
              <ol sx={{
                pl: '1.5rem',
                mb: 0
              }}>
                <li sx={{
                  fontSize: '1rem',
                  color: 'text',
                  lineHeight: 1.6,
                  mb: 2
                }}>
                  <strong>Assess Current Position:</strong> Evaluate your current skills, knowledge, strengths, and areas for improvement.
                </li>
                <li sx={{
                  fontSize: '1rem',
                  color: 'text',
                  lineHeight: 1.6,
                  mb: 2
                }}>
                  <strong>Define Career Vision:</strong> Clarify your long-term career aspirations and the skills needed to achieve them.
                </li>
                <li sx={{
                  fontSize: '1rem',
                  color: 'text',
                  lineHeight: 1.6,
                  mb: 2
                }}>
                  <strong>Set Development Goals:</strong> Create specific, measurable goals to bridge the gap between current skills and career aspirations.
                </li>
                <li sx={{
                  fontSize: '1rem',
                  color: 'text',
                  lineHeight: 1.6,
                  mb: 2
                }}>
                  <strong>Create Action Plan:</strong> Define specific learning activities, resources needed, and timeline for each goal.
                </li>
                <li sx={{
                  fontSize: '1rem',
                  color: 'text',
                  lineHeight: 1.6,
                  mb: 0
                }}>
                  <strong>Implement and Review:</strong> Execute the plan, track progress, reflect on learning, and adjust as needed.
                </li>
              </ol>
            </div>
          </div>
          
          {/* Right Column - Sidebar */}
          <div>
            {/* Download Template */}
            <div id="download" sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 10px rgba(0,0,0,0.08)',
              mb: 4
            }}>
              <h3 sx={{
                fontSize: '1.25rem',
                fontWeight: 600,
                color: 'text',
                mb: 3,
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <RiDownloadLine sx={{ color: 'primary' }} />
                Download Templates
              </h3>
              
              <p sx={{
                fontSize: '0.95rem',
                color: 'text',
                lineHeight: 1.5,
                mb: 3
              }}>
                Get our professionally designed development plan templates in multiple formats. Customize them for your organization.
              </p>
              
              <div sx={{
                display: 'grid',
                gap: 2,
                mb: 0
              }}>
                <Link to="/contact" sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'backgroundMuted',
                  color: 'text',
                  p: 2,
                  borderRadius: '6px',
                  textDecoration: 'none',
                  transition: 'transform 0.2s ease, background-color 0.2s ease',
                  '&:hover': {
                    bg: 'rgba(0,0,0,0.04)',
                    transform: 'translateY(-2px)'
                  }
                }}>
                  <RiDownloadLine sx={{ color: 'primary' }} />
                  <div>
                    <strong>Word Templates</strong>
                    <span sx={{ display: 'block', fontSize: '0.8rem', opacity: 0.8 }}>Editable .docx format</span>
                  </div>
                </Link>
                
                <Link to="/contact" sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'backgroundMuted',
                  color: 'text',
                  p: 2,
                  borderRadius: '6px',
                  textDecoration: 'none',
                  transition: 'transform 0.2s ease, background-color 0.2s ease',
                  '&:hover': {
                    bg: 'rgba(0,0,0,0.04)',
                    transform: 'translateY(-2px)'
                  }
                }}>
                  <RiDownloadLine sx={{ color: 'primary' }} />
                  <div>
                    <strong>PDF Templates</strong>
                    <span sx={{ display: 'block', fontSize: '0.8rem', opacity: 0.8 }}>Print-ready versions</span>
                  </div>
                </Link>
                
                <Link to="/contact" sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'backgroundMuted',
                  color: 'text',
                  p: 2,
                  borderRadius: '6px',
                  textDecoration: 'none',
                  transition: 'transform 0.2s ease, background-color 0.2s ease',
                  '&:hover': {
                    bg: 'rgba(0,0,0,0.04)',
                    transform: 'translateY(-2px)'
                  }
                }}>
                  <RiDownloadLine sx={{ color: 'primary' }} />
                  <div>
                    <strong>Excel Tracker</strong>
                    <span sx={{ display: 'block', fontSize: '0.8rem', opacity: 0.8 }}>Development tracking spreadsheet</span>
                  </div>
                </Link>
              </div>
            </div>
            
            {/* Quick Tips */}
            <div sx={{
              bg: 'backgroundMuted',
              p: 3,
              borderRadius: '8px',
              mb: 4,
              boxShadow: '0 2px 6px rgba(0,0,0,0.05)'
            }}>
              <h3 sx={{
                fontSize: '1.25rem',
                fontWeight: 600,
                color: 'primary',
                mb: 3
              }}>
                Quick Tips
              </h3>
              
              <div sx={{
                mb: 3
              }}>
                <div sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  mb: 1
                }}>
                  <RiCheckLine sx={{ color: 'primary' }} />
                  <p sx={{
                    fontSize: '0.95rem',
                    fontWeight: 600,
                    color: 'text'
                  }}>
                    Align with Organization
                  </p>
                </div>
                
                <p sx={{
                  fontSize: '0.9rem',
                  color: 'text',
                  opacity: 0.8,
                  lineHeight: 1.5,
                  ml: 4
                }}>
                  Connect your development goals to organizational needs and objectives.
                </p>
              </div>
              
              <div sx={{
                mb: 3
              }}>
                <div sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  mb: 1
                }}>
                  <RiCheckLine sx={{ color: 'primary' }} />
                  <p sx={{
                    fontSize: '0.95rem',
                    fontWeight: 600,
                    color: 'text'
                  }}>
                    Seek Feedback
                  </p>
                </div>
                
                <p sx={{
                  fontSize: '0.9rem',
                  color: 'text',
                  opacity: 0.8,
                  lineHeight: 1.5,
                  ml: 4
                }}>
                  Regularly solicit input from managers, mentors, and peers on your progress.
                </p>
              </div>
              
              <div>
                <div sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  mb: 1
                }}>
                  <RiCheckLine sx={{ color: 'primary' }} />
                  <p sx={{
                    fontSize: '0.95rem',
                    fontWeight: 600,
                    color: 'text'
                  }}>
                    Celebrate Progress
                  </p>
                </div>
                
                <p sx={{
                  fontSize: '0.9rem',
                  color: 'text',
                  opacity: 0.8,
                  lineHeight: 1.5,
                  ml: 4
                }}>
                  Acknowledge achievements along the way to maintain motivation.
                </p>
              </div>
            </div>
            
            {/* Related Tools */}
            <div sx={{
              bg: 'backgroundMuted',
              p: 3,
              borderRadius: '8px',
              boxShadow: '0 2px 6px rgba(0,0,0,0.05)'
            }}>
              <h3 sx={{
                fontSize: '1.25rem',
                fontWeight: 600,
                color: 'primary',
                mb: 3
              }}>
                Related Tools
              </h3>
              
              <div sx={{
                mb: 3,
                pb: 3,
                borderBottom: '1px solid',
                borderColor: 'rgba(0,0,0,0.05)'
              }}>
                <Link to="/tools/goal-setting-template" sx={{
                  textDecoration: 'none'
                }}>
                  <h4 sx={{
                    fontSize: '1rem',
                    fontWeight: 600,
                    color: 'text',
                    mb: 1
                  }}>
                    Goal Setting Template
                  </h4>
                  
                  <p sx={{
                    fontSize: '0.9rem',
                    color: 'text',
                    opacity: 0.8,
                    mb: 2
                  }}>
                    Create SMART goals to support your development plan objectives.
                  </p>
                  
                  <div sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: 'primary',
                    fontWeight: 600,
                    fontSize: '0.9rem'
                  }}>
                    Try Tool <RiArrowRightSLine sx={{ ml: 1 }} />
                  </div>
                </Link>
              </div>
              
              <div>
                <Link to="/tools/performance-review-builder" sx={{
                  textDecoration: 'none'
                }}>
                  <h4 sx={{
                    fontSize: '1rem',
                    fontWeight: 600,
                    color: 'text',
                    mb: 1
                  }}>
                    Performance Review Builder
                  </h4>
                  
                  <p sx={{
                    fontSize: '0.9rem',
                    color: 'text',
                    opacity: 0.8,
                    mb: 2
                  }}>
                    Incorporate development plans into comprehensive performance reviews.
                  </p>
                  
                  <div sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: 'primary',
                    fontWeight: 600,
                    fontSize: '0.9rem'
                  }}>
                    Try Tool <RiArrowRightSLine sx={{ ml: 1 }} />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* FAQ Section */}
      <div sx={{
        bg: 'backgroundMuted',
        py: [4, 5]
      }}>
        <div sx={{
          maxWidth: '960px',
          mx: 'auto',
          px: 3
        }}>
          <h2 sx={{
            fontSize: '1.75rem',
            fontWeight: 700,
            textAlign: 'center',
            mb: 4,
            color: 'text'
          }}>
            Frequently Asked Questions About Development Plans
          </h2>
          
          <div sx={{
            bg: 'white',
            borderRadius: '10px',
            p: 4,
            boxShadow: '0 2px 6px rgba(0,0,0,0.05)'
          }}>
            <div sx={{
              mb: 4,
              pb: 4,
              borderBottom: '1px solid',
              borderColor: 'rgba(0,0,0,0.06)'
            }}>
              <h3 sx={{
                fontSize: '1.25rem',
                fontWeight: 600,
                color: 'text',
                mb: 2,
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <RiQuestionLine sx={{ color: 'primary' }} />
                How often should I update my development plan?
              </h3>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 0
              }}>
                Most professionals benefit from reviewing their development plan quarterly and conducting a comprehensive 
                update annually. Quarterly check-ins allow you to track progress on short-term goals and make minor adjustments, 
                while annual reviews provide an opportunity to reflect on overall growth, celebrate achievements, and set new 
                long-term objectives. However, you should also revisit your plan whenever significant changes occur in your role, 
                career aspirations, or organizational needs.
              </p>
            </div>
            
            <div sx={{
              mb: 4,
              pb: 4,
              borderBottom: '1px solid',
              borderColor: 'rgba(0,0,0,0.06)'
            }}>
              <h3 sx={{
                fontSize: '1.25rem',
                fontWeight: 600,
                color: 'text',
                mb: 2,
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <RiQuestionLine sx={{ color: 'primary' }} />
                Who should be involved in creating my development plan?
              </h3>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 0
              }}>
                While creating a development plan is primarily a self-driven process, involving others can provide valuable 
                insights and support. Consider including: 1) Your direct manager, who can provide guidance on organizational 
                needs and career paths; 2) A mentor or trusted advisor who understands your field; 3) HR professionals who 
                can suggest relevant resources and programs; and 4) Peers who may offer fresh perspectives. Ultimately, you 
                should own your development plan, but input from these stakeholders can enhance its effectiveness and alignment 
                with organizational opportunities.
              </p>
            </div>
            
            <div sx={{
              mb: 4,
              pb: 4,
              borderBottom: '1px solid',
              borderColor: 'rgba(0,0,0,0.06)'
            }}>
              <h3 sx={{
                fontSize: '1.25rem',
                fontWeight: 600,
                color: 'text',
                mb: 2,
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <RiQuestionLine sx={{ color: 'primary' }} />
                What's the difference between a development plan and a career plan?
              </h3>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 0
              }}>
                While related, development plans and career plans serve different purposes. A career plan focuses on long-term 
                professional advancement, outlining potential career paths, future roles, and major milestones you want to achieve 
                over several years. A development plan is more immediate and skills-focused, detailing specific competencies to 
                develop in the short to medium term. Think of the career plan as your destination map, while the development plan 
                is your immediate route planner. Ideally, your development plan should support your career plan, with the skills 
                and experiences you're cultivating directly contributing to your long-term career aspirations.
              </p>
            </div>
            
            <div>
              <h3 sx={{
                fontSize: '1.25rem',
                fontWeight: 600,
                color: 'text',
                mb: 2,
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <RiQuestionLine sx={{ color: 'primary' }} />
                How do I measure progress in my professional development?
              </h3>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 0
              }}>
                Measuring professional development requires both quantitative and qualitative approaches. For quantitative 
                measurement, track completion of learning activities (courses, projects, certifications), frequency of applying 
                new skills, and improvements in relevant performance metrics. For qualitative assessment, gather feedback from 
                managers, peers, and mentors about observed improvements in your skills; conduct self-assessments comparing your 
                current abilities to your baseline; and document specific examples of how you've applied new knowledge or skills 
                in your work. Maintaining a development journal to record these observations can be particularly valuable for 
                tracking subtle improvements over time.
              </p>
            </div>
          </div>
        </div>
      </div>
      
      {/* CTA Section */}
      <div sx={{
        bg: 'white',
        py: [4, 5],
        textAlign: 'center'
      }}>
        <div sx={{
          maxWidth: '800px',
          mx: 'auto',
          px: 3
        }}>
          <h2 sx={{
            fontSize: '1.75rem',
            fontWeight: 700,
            color: 'text',
            mb: 3
          }}>
            Ready to Accelerate Your Professional Growth?
          </h2>
          
          <p sx={{
            fontSize: '1.1rem',
            color: 'text',
            lineHeight: 1.5,
            mb: 4
          }}>
            Start creating your structured development plan today and take control of your 
            career advancement with our comprehensive templates.
          </p>
          
          <div sx={{
            display: 'flex',
            gap: 3,
            justifyContent: 'center',
            flexWrap: 'wrap'
          }}>
            <Link to="/contact" sx={{
              display: 'inline-flex',
              alignItems: 'center',
              gap: 2,
              bg: 'primary',
              color: 'white',
              py: 2,
              px: 3,
              borderRadius: '6px',
              fontSize: '1rem',
              fontWeight: 600,
              textDecoration: 'none',
              transition: 'transform 0.2s ease, box-shadow 0.2s ease',
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
              }
            }}>
              Get Free Templates <RiDownloadLine />
            </Link>
            
            <Link to="/guides/career-development" sx={{
              display: 'inline-flex',
              alignItems: 'center',
              gap: 2,
              bg: 'backgroundMuted',
              color: 'text',
              py: 2,
              px: 3,
              borderRadius: '6px',
              fontSize: '1rem',
              fontWeight: 600,
              textDecoration: 'none',
              transition: 'transform 0.2s ease',
              '&:hover': {
                transform: 'translateY(-2px)'
              }
            }}>
              Read Career Development Guide <RiArrowRightSLine />
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default DevelopmentPlanTemplatePage 